import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import S1mpl from "../../components/Portfolio/S1mpl"

const S1mplPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Home" keywords={[`BMR`, `labels`]} />
    <S1mpl />
  </Layout>
)

export default S1mplPage
